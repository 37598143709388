import React from "react"
import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types"
import { ThemeContext } from "../layouts";
import Article from "../components/Article/";
import Headline from "../components/Article/Headline";
// Components
import { Link, graphql } from "gatsby"

const ingredients = ({ pageContext, data }) => {
  const { ingredient } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const ingredientHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${ingredient}"`

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline theme={theme}>
              <span>Recipes with</span> <FaTag />
                {ingredient}
              </Headline>
        {edges.map(({ node }) => {
          const {title } = node.frontmatter
          var path = node.fields.slug
          return (
            <li key={path}>
              <Link to={path}>{title}</Link>
            </li>
          )
        })}
        </header>
        <hr/>
       <Link to="/ingredients">All ingredients</Link>


           </Article>
         )}
       </ThemeContext.Consumer>
     </React.Fragment>


  )
}

ingredients.propTypes = {
  pathContext: PropTypes.shape({
    ingredient: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default ingredients

export const pageQuery = graphql`
  query($ingredient: String) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { ingredients: { in: [$ingredient] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            author
          }
          fields{
            slug
          }
        }
      }
    }
  }
`
